


import FETCHING from '../../library/fetching'
import UMUM from '../../library/umum'
import DATA_MASTER from '../../library/dataMaster'

export default {
  data() {
    return {

      form : {
        id : '',
        type_kegiatan  : '',
        tahun_anggaran : '',
        kd_satker : '',
        kd_satker_str : '',
        nama_satker : '',
        kd_rup : '',
        kd_rup_lokal : '',
        kd_klpd : '',
        nama_klpd : '',
        jenis_klpd : '',
        nama_paket : '',
        nama_ppk : '',
        nip_ppk : '',
        username_ppk : '',
        pagu : '',
        tgl_buat_paket : '',
        tgl_pengumuman_paket : '',
        uraian_pekerjaan : '',
        volume_pekerjaan : '',
        status_aktif_rup : '',
        status_delete_rup : '',
        status_umumkan_rup : '',

        kd_klpd_penyelenggara : '',
        nama_klpd_penyelenggara : '',
        nama_satker_penyelenggara : '',
        tgl_akhir_pelaksanaan_kontrak : '',
        tgl_awal_pelaksanaan_kontrak : '',
        tipe_swakelola : '',

        alasan_non_ukm : '',
        jenis_pengadaan : '',
        kd_jenis_pengadaan : '',
        kd_metode_pengadaan : '',
        kd_rup_swakelola : '',
        metode_pengadaan : '',
        spesifikasi_pekerjaan : '',
        status_konsolidasi : '',
        status_pdn : '',
        status_pradipa : '',
        status_ukm : '',
        tgl_akhir_kontrak : '',
        tgl_akhir_pemanfaatan : '',
        tgl_akhir_pemilihan : '',
        tgl_awal_kontrak : '',
        tgl_awal_pemanfaatan : '',
        tgl_awal_pemilihan : '',
        tipe_paket : '',

        nama_subkegiatan : '',
        kd_subkegiatan_str : '',
        nama_kegiatan : '',
        kd_kegiatan_str : '',
        nama_program : '',
        kd_program_str : '',

        type_kegiatan : '',

      },

      filterku : {
        kd_satker : '',
        tahun_anggaran  : '',
        type_kegiatan : '',
        status_umumkan_rup : '',
      },

      // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      
    
      list_data : [],

      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",
      cek_load_data : true,


      mdl_detile: false,
      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,


      FETCHING : FETCHING,
      UMUM : UMUM,
      DATA_MASTER : DATA_MASTER,
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_CT_RUP_FINAL + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              page_limit : this.page_limit,


              kd_satker : this.filterku.kd_satker,
              tahun_anggaran  : this.filterku.tahun_anggaran,
              type_kegiatan : this.filterku.type_kegiatan,
              status_umumkan_rup : this.filterku.status_umumkan_rup,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },


    addData : function(number) {
      fetch(this.$store.state.url.URL_CT_RUP_FINAL + "Add", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      fetch(this.$store.state.url.URL_CT_RUP_FINAL + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_CT_RUP_FINAL + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },

    selectData : function(data){


        console.log(data)

        this.form.id = data.id;

          this.form.id = data.id ;
          this.form.type_kegiatan  = data.type_kegiatan ;
          this.form.tahun_anggaran = data.tahun_anggaran ;
          this.form.kd_satker = data.kd_satker ;
          this.form.kd_satker_str = data.kd_satker_str ;
          this.form.nama_satker = data.nama_satker ;
          this.form.kd_rup = data.kd_rup ;
          this.form.kd_rup_lokal = data.kd_rup_lokal ;
          this.form.kd_klpd = data.kd_klpd ;
          this.form.nama_klpd = data.nama_klpd ;
          this.form.jenis_klpd = data.jenis_klpd ;
          this.form.nama_paket = data.nama_paket ;
          this.form.nama_ppk = data.nama_ppk ;
          this.form.nip_ppk = data.nip_ppk ;
          this.form.username_ppk = data.username_ppk ;
          this.form.pagu = data.pagu ;
          this.form.tgl_buat_paket = data.tgl_buat_paket ;
          this.form.tgl_pengumuman_paket = data.tgl_pengumuman_paket ;
          this.form.uraian_pekerjaan = data.uraian_pekerjaan ;
          this.form.volume_pekerjaan = data.volume_pekerjaan ;
          this.form.status_aktif_rup = data.status_aktif_rup ;
          this.form.status_delete_rup = data.status_delete_rup ;
          this.form.status_umumkan_rup = data.status_umumkan_rup ;

          this.form.kd_klpd_penyelenggara = data.kd_klpd_penyelenggara ;
          this.form.nama_klpd_penyelenggara = data.nama_klpd_penyelenggara ;
          this.form.nama_satker_penyelenggara = data.nama_satker_penyelenggara ;
          this.form.tgl_akhir_pelaksanaan_kontrak = data.tgl_akhir_pelaksanaan_kontrak ;
          this.form.tgl_awal_pelaksanaan_kontrak = data.tgl_awal_pelaksanaan_kontrak ;
          this.form.tipe_swakelola = data.tipe_swakelola ;
       
          this.form.alasan_non_ukm = data.alasan_non_ukm ;
          this.form.jenis_pengadaan = data.jenis_pengadaan ;
          this.form.kd_jenis_pengadaan = data.kd_jenis_pengadaan ;
          this.form.kd_metode_pengadaan = data.kd_metode_pengadaan ;
          this.form.kd_rup_swakelola = data.kd_rup_swakelola ;
          this.form.metode_pengadaan = data.metode_pengadaan ;
          this.form.spesifikasi_pekerjaan = data.spesifikasi_pekerjaan ;
          this.form.status_konsolidasi = data.status_konsolidasi ;
          this.form.status_pdn = data.status_pdn ;
          this.form.status_pradipa = data.status_pradipa ;
          this.form.status_ukm = data.status_ukm ;
          this.form.tgl_akhir_kontrak = data.tgl_akhir_kontrak ;
          this.form.tgl_akhir_pemanfaatan = data.tgl_akhir_pemanfaatan ;
          this.form.tgl_akhir_pemilihan = data.tgl_akhir_pemilihan ;
          this.form.tgl_awal_kontrak = data.tgl_awal_kontrak ;
          this.form.tgl_awal_pemanfaatan = data.tgl_awal_pemanfaatan ;
          this.form.tgl_awal_pemilihan = data.tgl_awal_pemilihan ;
          this.form.tipe_paket = data.tipe_paket ;

          this.form.nama_subkegiatan = data.nama_subkegiatan ;
          this.form.kd_subkegiatan_str = data.kd_subkegiatan_str ;
          this.form.nama_kegiatan = data.nama_kegiatan ;
          this.form.kd_kegiatan_str = data.kd_kegiatan_str ;
          this.form.nama_program = data.nama_program ;
          this.form.kd_program_str = data.kd_program_str ;

    },

    syncKode : function() {
      fetch(this.$store.state.url.URL_CT_RUP_FINAL + "syncData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              tahun_anggaran: this.filterku.tahun_anggaran,
          })
      }).then(res_data => {
          this.Notify('Sukses Sinkronisasi Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    // ====================================== CONTOH eDOC ====================================
    onClickx(data){
      alert(data)
    },
    // ====================================== CONTOH eDOC ====================================

  // ====================================== CONTOH AUTOCOMPLETE ====================================
    autocomplete_db_filter : function (val, update) {
        update(() => {
          if (val === '') {}
          else {FETCHING.getContohAtocomplete(val)}
        })
    },

    // ====================================== CONTOH AUTOCOMPLETE ====================================








    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {
    // FETCHING.getContohAtocomplete('')

    const d = new Date();
    let year = d.getFullYear();

    this.filterku.tahun_anggaran = year
    this.getView();
  },
}
